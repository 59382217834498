<template>
    <div data-aos="zoom-in-right" data-aos-duration="1000" class="relative max-w-xs mt-10 overflow-hidden bg-cover bg-no-repeat rounded-md shadow-lg shadow-black">
        <img :src="data.image" class="max-w-xs" alt="">
        <span v-for="(tag, index) in data.tags" :key="index" class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{{ tag }}</span>
        <div 
        class="projects-cover">
        <h1 class="font-experience-title font-bold"><a :href="data.link">{{ data.title }}</a></h1>
        <p>{{ data.description }}</p>
    </div>
</div>
</template>
<script>
import AOS from 'aos'
export default {
    props: {
        data: {
            type: Object,
            required:true,
        }
    },
    mounted() {
        AOS.init();
    },
}
</script>
<style scoped>
img {
    height: 15rem;
    width: 100%;
    object-fit: contain;
}
</style>
